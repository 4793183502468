@import 'src/styles/constants';

.Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 1px solid $orange-condigel;
  padding-bottom: 1rem;
}

.HeaderText {
  font-size: 24px;
  font-weight: bold;
  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
}

.ArrowIcon {
  margin: 0 0.5rem;
}

.Wrapper {
  margin-bottom: 1rem;
  width: 100%;
}
.WrapperCart {
  margin-bottom: 1rem;
  width: 100%;
  overflow-y: auto;
}

.WrapperChart {
  margin-bottom: 1rem;
  width: 100%;
}

.Wrapper50 {
  margin-bottom: 1rem;
  width: 100%;
}

.TextWrapper {
  display: flex;
  width: 50%;
}

.WrapperRow {
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
  justify-content: space-between;
}

.WrapperColumn {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
}
.WrapperMovementColumn {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  overflow-y: auto;
  max-height: 30vh;
}

.ModalText {
  display: flex;
  font-size: 16px;
  text-align: left;
  word-wrap: normal;
  word-break: break-all;
  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.ModalTextBold {
  font-size: 16px;
  text-align: left;
  font-weight: bold;
  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.TextUnderline {
  font-size: 16px;
  text-decoration-line: underline;
  margin-right: 1rem;
  @media only screen and (max-width: 600px) {
    font-size: 14px;
    margin-right: 0.5rem;
  }
}

.CrossLogo {
  cursor: pointer;

  &:hover {
    transform: scale(1.15, 1.15);
    opacity: 0.9;
  }
}

.ButtonWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.SplitSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .position {
    display: flex;
    flex-direction: column;
    width: 49%;
  }

  .positionCheckbox {
    display: flex;
    flex-direction: column;
    width: 49%;
    justify-content: center;
    margin-top: 1rem;
  }
}

.DatePicker {
  display: flex;
  background-color: #FFFFFF;
  height: 40px;
  border-radius: 4px;
  border: 1px solid $green;
  width: 99%;
  cursor: pointer;
  margin-bottom: 1rem;
  padding: 16px;
}

.test {
  display: flex;
  justify-content: center;
}

.WrapperCenter {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.FormFullWidth {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 90%;
  justify-content: flex-start;
}

.IconRightWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 20%;

  .Icon {
    margin-right: 1rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.15, 1.15);
      opacity: 0.9;
    }

    &:active {
      opacity: 0.5;
    }
  }

  .CrossLabel {
    cursor: pointer;
    margin-right: 1rem;
    font-size: 16px;
    font-weight: bold;
    color: $orange-condigel;

    &:hover {
      transform: scale(1.15, 1.15);
      opacity: 0.9;
    }
  }
}
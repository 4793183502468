@import '../../styles/constants';

.Widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 0.5rem 1.3rem;
  cursor: pointer;

  &:hover {
    transform: scale(1.015, 1.015);
    opacity: 0.9;
  }

  &:active {
    opacity: 0.5;
  }
}

.OuterWidget {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: $green;
  border-radius: 10px;
  border: 2px solid #000000;
  height: 5.6rem;
  width: 5.6rem;
  margin-bottom: 0;
  text-decoration: none;
}

.InnerWidget {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 10px;
  border: 2px solid #000000;
  height: 4rem;
  width: 4rem;
  color: black;
  /*@media only screen and (max-width: 420px) {
    height: 2.6rem;
    width: 2.6rem;
  }*/
}

.Label {
  margin-top: 1rem;
}

.Logo {
  font-size: 40px;
  border: 0;
  /*@media only screen and (max-width: 420px) {
    font-size: 26px;
  }*/
}
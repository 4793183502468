.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 1000px;
  max-width: 90%;
  justify-content: flex-start;
  height: 100%;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}

.SplitSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .position {
    display: flex;
    flex-direction: column;
    width: 49%;

  }

  .positionHidden {
    display: none;
  }
}

.LoaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Label {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-left: 5px;
}

.WrapperSplit {
  display: flex;
  flex-direction: column;
  width: 45%;
  @media only screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.MissionWrapper {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-around;
  @media only screen and (max-width: 720px) {
    display: flex;
    flex-direction: column;
    width: 90%;
  }
}

.BreadcrumbsWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 1rem;
}
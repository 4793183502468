.CustomLabel {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 80%;
}

.CustomIconLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Label {
  font-size: 1rem;
  width: 100%;
  @media only screen and (max-width: 600px) {
    font-size: 0.9rem;
  }
}

.CenteredLabel {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  width: 100%;
  @media only screen and (max-width: 600px) {
    font-size: 0.9rem;
  }
}

.IconLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  width: 100%;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    font-size: 0.65rem;
  }
}
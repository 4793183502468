@import '../../../styles/constants';

.BasicButtonContainer {
  width: 30%;
  @media only screen and (max-width: 290px) {
    width: 100%;
  }
  margin-right: 1rem;
}

.BasicButton {
  display: flex;
  background-color: $green;
  height: 2.2rem;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  border: 0;
  color: white;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 0 10px 0 #00000082;

  &:hover {
    background-color: $green;
    color: black;
    transform: scale(1.005,1.005);
    opacity: 0.9;
  }

  &:active {
    opacity: 0.5;
  }
}

.DisabledButton {
  display: flex;
  background-color: darkgrey;
  height: 2.2rem;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  color: white;
  width: 100%;
  box-shadow: 0 0 10px 0 #00000082;
  opacity: 0.5;
}
@import '../../../styles/constants';

.ButtonContainer {
  width: 30%;
}

.BasicButtonContainer {
  width: 100%;
}

.SuccessButton {
  display: flex;
  background-color: $green;
  height: 3.4rem;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 4px;
  color: white;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 0 10px 0 #00000082;
  &:hover {
    background-color: $green;
    color: black;
    transform: scale(1.005,1.005);
    opacity: 0.9;
  }

  &:active {
    opacity: 0.5;
  }
}

.ModifyButton {
  display: flex;
  background-color: $orange;
  height: 3.4rem;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 4px;
  color: white;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 0 10px 0 #00000082;

  &:hover {
    background-color: $orange;
    color: black;
    transform: scale(1.005,1.005);
    opacity: 0.9;
  }

  &:active {
    opacity: 0.5;
  }
}

.CancelButton {
  display: flex;
  background-color: $red;
  height: 3.4rem;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 4px;
  color: white;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 0 10px 0 #00000082;

  &:hover {
    background-color: $red;
    color: black;
    transform: scale(1.005,1.005);
    opacity: 0.9;
  }

  &:active {
    opacity: 0.5;
  }
}
.DisabledButton {
  display: flex;
  background-color: darkgrey;
  height: 3.4rem;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: white;
  width: 100%;
  box-shadow: 0 0 10px 0 #00000082;
  opacity: 0.5;
}
@import '../../styles/constants';

.BasicCheckbox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.CenteredCheckbox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NotifyCheckbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 1rem;
}

[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}

input[type='checkbox'] {
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 25px;
  height: 25px;
  border: 1px solid $green;
  border-radius: 4px;
  transform: translateY(-0.075rem);
  display: grid;
  place-content: center;
  margin-right: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  &::before {
    content: '';
    width: 1rem;
    height: 1rem;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1rem 1rem var(--form-control-color);
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%)
  }

  &:checked::before {
    transform: scale(1);
    background-color: $green;
  }

  &:disabled {
    opacity: 0.3;
    &:hover {
      cursor: default;
    }
  }
}

//input[type='checkbox']:focus {
//  outline: max(1px, 0.15em) solid $green;
//  outline-offset: max(2px, 0.15em);
//}


.Container {
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height:100%;
  overflow-y: auto;
  overflow-x: hidden;
}
@import '../../styles/constants';

.Text {
  display: flex;
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;
  width: 100%;
  @media only screen and (max-width: 440px) {
    font-size: 14px;
  }
}

.ParkTextRight {
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;
  width: 100%;
  @media only screen and (max-width: 440px) {
    font-size: 14px;
  }
}
.ParkTextLeft {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;
  width: 100%;
  @media only screen and (max-width: 440px) {
    font-size: 14px;
  }
}

.TextLeft {
  display: flex;
  font-size: 16px;
  margin: 0 0 0 10px;
  cursor: pointer;
}

.TextRight {
  display: flex;
  font-size: 16px;
  margin: 0 10px 0 0;
  cursor: pointer;
}

.TextBold {
  display: flex;
  font-size: 16px;
  margin: 0 10px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;

  @media only screen and (max-width: 440px) {
    font-size: 14px;
  }
}

.Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid $green;
  border-radius: 7px;
  margin: 5px 0;
  width: 100%;
  min-height: 40px;
  padding: 0.5rem 0 0.5rem 0;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);

  &:hover {
    transform: scale(1.015, 1.015);
    opacity: 0.9;
    border: 1px solid $orange-condigel;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.45);

  }

  &:active {
    opacity: 0.5;
  }
}

.WrapperRed {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid red;
  border-radius: 7px;
  margin: 10px 0;
  width: 100%;
  min-height: 40px;
  padding: 0.5rem 0 0.5rem 0;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);

  &:hover {
    transform: scale(1.015, 1.015);
    opacity: 0.9;
    border: 1px solid $orange-condigel;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.45);

  }

  &:active {
    opacity: 0.5;
  }
}

.ParkTextWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid $green;
  border-radius: 7px;
  margin: 10px 0;
  width: 100%;
  min-height: 40px;
  padding: 0.5rem 0 0.5rem 0;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);

  &:hover {
    transform: scale(1.015, 1.015);
    opacity: 0.9;
    border: 1px solid $orange-condigel;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.45);

  }

  &:active {
    opacity: 0.5;
  }

  @media only screen and (max-width: 740px) {
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
  }
}

.WrapperRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.WrapperRowParkExtend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-top: 1rem;
}

.WrapperColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 5px 0;
  width: 100%;
}

.WrapperIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.15, 1.15);
    opacity: 0.9;
  }
}

.WrapperRowIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.TextWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5px 0;
  width: 100%;
  cursor: pointer;
}

.TextWrapperColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 5px 0;
  width: 100%;
  cursor: pointer;
}

.SelectWrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5px 0;
  width: 30%;
  cursor: pointer;
}

.ArrowIcon {
  margin: 0 0.5rem;
}

.Actions {
  display: flex;
  width: 100%;
  margin-top: 0.7rem;
  justify-content: space-evenly;
  align-items: center;
}

.IconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Icon {
  &:hover {
    transform: scale(1.15, 1.15);
    opacity: 0.9;
  }
}




@import '../../styles/constants';

.CustomInput {
  display: flex;
  width:100%;
}

.Input {
  border: 1px solid $green;
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  width: 100%;
  min-width: 80%;
  &:focus {
    transform: scale(1.005,1.005);
    border: 2px solid $green;
    outline: none;
  }
  &:disabled {
    opacity: 0.5;
  }
}

.InputArea {
  display: flex;
  border: 1px solid $green;
  border-radius: 4px;
  padding: 0 16px;
  height: 80px;
  width: 100%;
  min-width: 80%;
  justify-content: center;
  align-content: center;
  &:focus {
    transform: scale(1.005,1.005);
    border: 2px solid $green;
    outline: none;
  }
}
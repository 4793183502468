@import '../../styles/constants';

.Footer {
  display: flex;
  align-items: center;
  background-color: $green;
  color: white;
  width: 100%;
  box-shadow: 0 -8px 15px rgba(0, 0, 0, 0.30);
  margin-top: 1rem;
  flex-shrink: 0;
  position: sticky;
  height: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;

  @media only screen and (max-width: 440px) {
    height: 30px;
  }

  p {
    text-align: center;
    font-size: 14px;
    @media only screen and (max-width: 720px) {
      font-size: 12px;
    }
  }

  .Left {
    float: left;
    margin-left: 1rem;
  }

  .Center {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Right {
    float: right;
    margin-right: 1rem;
  }
}

@import '../../styles/constants';

.Header {
  display: flex;
  background-color: $green;
  color: white;
  font-size: 1.5rem;
  width: 100%;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.30);
  margin-bottom: 1rem;
  flex-shrink: 0;
  position: relative;

  top: 0;

  p {
    text-align: center;
    margin: 0;
    @media only screen and (max-width: 720px) {
      font-size: 16px;
    }
  }
}

.Wrap {

}

.IconWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  .Icon {
    margin-right: 1rem;
    cursor: pointer;

    &:hover {
      transform: scale(1.2, 1.2);
      opacity: 0.9;
    }

    &:active {
      opacity: 0.5;
    }
  }
}

.ContainerLogo {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.Center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Logo {
  width: 120px;
  height: 75px;

  &:hover {
    transform: scale(1.015, 1.015);
  }

  @media only screen and (max-width: 400px) {
    width: 100px;
    height: 75px;
  }
  @media only screen and (max-width: 350px) {
    width: 95px;
    height: 65px;
  }
  @media only screen and (max-width: 330px) {
    width: 85px;
    height: 65px;
  }
}

@import '../../styles/constants';

.Breadcrumbs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start ;
  margin-bottom: 1rem;
  width: 100%;
  margin-left: 1rem;
}

.Link {
  color: black;
  &:hover {
    color: $green;
  }
  &:active {
    opacity: 0.5;
  }
}
@import '../../styles/constants';

.Form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 1000px;
  max-width: 90%;
  justify-content: flex-start;
  height: 100%;
}

.FormFullWidth {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 90%;
  justify-content: flex-start;
}

.SplitSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .position {
    display: flex;
    flex-direction: column;
    width: 49%;
    justify-content: center;
  }

  .positionHidden {
    display: none;
  }
}

.SplitSelectColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .position {
    display: flex;
    flex-direction: column;
    width: 49%;
    justify-content: center;
  }

  .positionHidden {
    display: none;
  }
}

.CustomSearchableSelect {
  width: 100%;
  margin-bottom: 1rem;
}

.DatePicker {
  display: flex;
  background-color: #FFFFFF;
  height: 40px;
  border-radius: 4px;
  border: 1px solid $green;
  width: 99%;
  cursor: pointer;
  margin-bottom: 1rem;
  padding-left: 16px;
}

.Card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid $green;
  border-radius: 7px;
  width: 100%;
  background-color: #FFFFFF;
}

.CardHeader {
  width: 100%;
  margin: 10px 0 20px 0;
  padding: 0 0 10px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $orange-condigel;

  .IconRightWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 20%;

    .Icon {
      margin-right: 1rem;
      cursor: pointer;

      &:hover {
        transform: scale(1.15, 1.15);
        opacity: 0.9;
      }

      &:active {
        opacity: 0.5;
      }
    }
  }

  .IconLeftWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 20%;

    .Icon {
      margin-left: 1rem;
    }
  }

  .CenterWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
  }

  @media only screen and (max-width: 720px) {
    font-size: 19px;
  }
}

.CardBody {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.7rem;
}

.CardTitle {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  @media only screen and (max-width: 720px) {
    font-size: 17px;
  }
}

.InnerCard {
  display: flex;
  width: 90%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.CardTextUnderlined {
  margin-right: 15px;
  @media only screen and (max-width: 720px) {
    font-size: 16px;
    margin-right: 10px;
  }
  @media only screen and (max-width: 720px) {
    font-size: 14px;
    margin-right: 7px;
  }
}

.CardText {
  text-decoration-line: none;
  margin-left: auto;
  @media only screen and (max-width: 720px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 720px) {
    font-size: 14px;
  }
}

p {
  margin: 0;
}

.ButtonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.Wrapper {
  width: 100%;
  margin-bottom: 1rem;
}
.WrapperRow {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}

.TextWrapper {
  display: flex;
  width: 100%;
}

.WrapperArrivalCenter {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}

.Export {
  text-decoration: none;
  background-color: $green;
  color: white;
  padding: 0.5rem;
  border-radius: 7px;
  font-size: 16px;
  border: 0.1rem solid black;
  box-shadow: 0 0 10px 0 #00000082;

  &:hover {
    transform: scale(1.03, 1.03);
  }

  &:active {
    opacity: 0.5;
  }
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.DefaultLayout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height:100vh;
}

.Missing {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.Title {
  font-size: 40px;
}

.TextWrapper {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}

.WidgetWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}